module.exports = function() {
	return {
		restrict: 'E',
		scope: {
		    searchParam: '=ngModel',
		    searchResults: '=data',
		    onType: '=onType',
		    onSelect: '=onSelect'
		},
		controller: function ($scope, focus) {
		    focus('focusMe');

		    $scope.autopopulateIsSafe = false;

		    $scope.totalResultCount = function () {
		        var totalCount = 0;

		        for (var i = 0; i < $scope.searchResults.length; i++) {
		            totalCount += $scope.searchResults[i].suggestions.length;
		        }
		        return totalCount;
		    }

		    $scope.makingDataCall = false;
		    $scope.selectedId = 0;
		    $scope.initLock = true;
		    $scope.lastKeyCode = null;
		    $scope.previouslyMatched = null;
		    var typingTimer;
		    var doneTypingInterval = 500;

		    $scope.moveUp = function () {
		        var newSelectedId = $scope.selectedId - 1;

		        if (newSelectedId > 0)
		            $scope.setSelectedId(newSelectedId);
		        else if(newSelectedId == -1)
		            $scope.setSelectedId($scope.totalResultCount());
		        else
		            $scope.setSelectedId(0);
		    }

		    $scope.moveDown = function () {
		        var newSelectedId = $scope.selectedId + 1;

		        if (newSelectedId < ($scope.totalResultCount()+1))
		            $scope.setSelectedId(newSelectedId);
		        else
		            $scope.setSelectedId(0);
		    }

		    $scope.reset = function () {
		        $scope.setSelectedId(0);
		    }

		    this.hoverHighlight = function (id) {
		        $scope.setSelectedId(id);
		    }

		    this.hoverReset = function () {
		        $scope.setSelectedId(0);
		    }

		    // set new selected Id
		    $scope.setSelectedId = function (i) {
		        $scope.selectedId = parseInt(i);
		        $scope.$apply();
		    };

		    // watches if the parameter filter should be changed
		    var watching = true;

		    // autocompleting drop down on/off
		    $scope.completing = false;

		    // starts autocompleting on typing in something
		    $scope.$watch('searchParam', function (newValue, oldValue) {
		        if (oldValue === newValue || (!oldValue && $scope.initLock)) {
		            return;
		        }

		        if (watching && typeof $scope.searchParam !== 'undefined' && $scope.searchParam !== null) {
		            $scope.completing = true;
		            $scope.searchFilter = $scope.searchParam;
		            //$scope.selectedIndex = -1;
		        }

		        // function thats passed to on-type attribute gets executed
		        if ($scope.onType) {
		            clearTimeout(typingTimer);
		            typingTimer = setTimeout(function () { $scope.onType($scope.searchParam); }, doneTypingInterval);
		        }

		    });

		    // selecting a suggestion with RIGHT ARROW or ENTER
		    $scope.select = function (suggestionId, suggestionName) {
		        $scope.onSelect(suggestionId);
		        watching = false;
		        $scope.completing = false;
		    };

		    // selecting a suggestion with RIGHT ARROW or ENTER
		    $scope.selectLast = function () {
		        $scope.onSelect($scope.selectedId);
		        watching = false;
		        $scope.completing = false;
		    };

		    $scope.full_search = function () {
		        $scope.onSelect(0);
		        watching = false;
		        $scope.completing = false;
		    };

		},
		link: function (scope, element, attrs) {

		    setTimeout(function () {
		        scope.initLock = false;
		        scope.$apply();
		    }, 250);

		    var attr = '';

		    //notes: title text, placeholder text is defined dyanmically by the web part. set the placeholder text there if possible...
		    // Default atts
		    scope.attrs = {
		        "placeholder": "start typing...",
		        "class": "",
		        "id": "",
		        "inputclass": "",
		        "inputid": ""
		    };

		    for (var a in attrs) {
		        attr = a.replace('attr', '').toLowerCase();
		        // add attribute overriding defaults
		        // and preventing duplication
		        if (a.indexOf('attr') === 0) {
		            scope.attrs[attr] = attrs[a];
		        }
		    }

		    if (attrs.clickActivation) {
		        element[0].onmousedown = function (e) {
		            if (scope.searchParam && scope.searchParam.length > 0) {
		                scope.completing = true;
		                scope.$apply();
		            }
		        };
		    }

		    var key = { left: 37, up: 38, right: 39, down: 40, enter: 13, esc: 27, tab: 9, backspace: 8 };

		    element[0].addEventListener("blur", function (e) {

		        var keys = [, ];
		        var targ = e['target'];

		        // disable suggestions on blur
		        // we do a timeout to prevent hiding it before a click event is registered
		        setTimeout(function () {
		            scope.completing = false;
		            //scope.setIndex(-1);
		            scope.$apply();
		        }, 200);
		    }, true);

		    element[0].addEventListener("keydown", function (e) {
		        var keycode = e.keyCode || e.which;
		        scope.lastKeyCode = keycode;

		        // implementation of the up and down movement in the list of suggestions
		        switch (keycode) {
		            case key.up:
		                scope.moveUp();
		                break;

		            case key.down:
		                scope.moveDown();
		                break;

		            case key.left:
		                break;

		            case key.right:
		            	break;

		            case key.enter:
		                scope.selectLast();
		                break;

		            case key.tab:
		                scope.$apply();
		                break;

		            case key.esc:
		                scope.$apply();
		                e.preventDefault();
		                break;

		            case key.backspace:
		                scope.reset();
		                break;

		            default:
		                return;
		        }

		    });
		},
		templateUrl: "/CMSWebParts/NRA/SmartSearchOverlay_files/script/views/predictiveSearchForm.html"
	};
};