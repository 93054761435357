﻿angular.element(document).ready(function () {
	var appId = null;
	var appNode = null;
	var apps = $('.search-overlay-app');

	$.each(apps, function (index, value) {
		var currentAppNode = $(value);

		if (!currentAppNode.attr('claimed')) {
			appNode = currentAppNode;
			appId = currentAppNode.attr('app-id');
			var sindexes = currentAppNode.attr('srch-ids');
			var dftUrl = currentAppNode.attr('default-url');
			var preSearchTxt = currentAppNode.attr('presearch-text');
			var titleTxt = currentAppNode.attr('title-text');
			var siteSearchApp = angular.module(appId, ['angularModalService', 'predictiveSearchApp']);

			siteSearchApp.controller('SiteSearchController', function ($scope, ModalService) {
				$scope.showComplex = function () {
					ModalService.showModal({
						templateUrl: "/CMSWebParts/NRA/SmartSearchOverlay_files/script/views/predictiveSearchMain.html",
						controller: "SiteSearchResultsController",
						inputs: {
							title: titleTxt,
							searchIndexes: sindexes,
							defaultUrl: dftUrl,
							preSearchText: preSearchTxt
						}
					})

					.then(function (modal) {
						$(modal.element).modal();
						modal.close.then(function (result) { });
					});
				};
			});

			siteSearchApp.controller('SiteSearchResultsController',
				function ($scope, $element, title, searchIndexes, defaultUrl, preSearchText, close) {
					$scope.title = title;
					$scope.searchIndexes = searchIndexes;
					$scope.defaultUrl = defaultUrl;
					$scope.preSearchText = preSearchText;

					//  This close function doesn't need to use jQuery or bootstrap, because
					//  the button has the 'data-dismiss' attribute.
					$scope.close = function () {
						close({
						}, 500); // close, but give 500ms for bootstrap to animate
					};

					//  This cancel function must use the bootstrap, 'modal' function because
					//  the doesn't have the 'data-dismiss' attribute.
					$scope.cancel = function () {

						//  Manually hide the modal.
						$element.modal('hide');

						//  Now call close, returning control to the caller.
						close({
						}, 500); // close, but give 500ms for bootstrap to animate
					};
				});

			currentAppNode.attr('claimed', true);
			angular.bootstrap(currentAppNode, [appId]);
		}
	});
});