module.exports = function() {
    return {
        restrict: 'A',
        require: '^predictiveSearchDirective', // ^look for controller on parents element
        link: function (scope, element, attrs, autoCtrl) {
            element.bind('mouseenter', function () {
                autoCtrl.hoverHighlight(attrs.val);
            });

            element.bind('mouseleave', function () {
                autoCtrl.hoverReset();
            });
        }
    };
};