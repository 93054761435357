module.exports = function($http, $q, $timeout, $filter, $window) {
	var PredictiveSearchData = new Object();

    PredictiveSearchData.searchdata = function (typedThings, searchIndexes) {
        var returndata = $q.defer();

        if (typedThings.length > 2) {
            var datasource;
            $http({
                method: 'GET',
                url: '/api/GetSiteSearch/',
                params: { searchText: typedThings, sindexes: searchIndexes }
            })
            .then(function (response) {
                returndata.resolve(response.data);
            });
        }
        else {
            returndata.resolve([]);
        }

        return returndata.promise
    }

    PredictiveSearchData.getItemById = function (id, filterData) {
        var found = null;
        for (var i = 0; i < filterData.length; i++) {
            found = $filter('filter')(filterData[i].suggestions, { id: id }, true);
            if (found.length > 0)
                break;
        }

        if (found.length > 0) {
            return found[0];
        } else {
            return undefined;
        }
    }

    PredictiveSearchData.redirect = function (path) {
        $window.location = path;
    }

    return PredictiveSearchData;
};