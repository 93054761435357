module.exports = function($scope, predictiveSearchData) {
	$scope.searchIndexes;
	$scope.defaultUrl;
	$scope.searchText;

	focus('focusMe');

	$scope.autoData = predictiveSearchData.searchdata('', $scope.searchIndexes);
	$scope.autoData.then(function (data) {
		$scope.autoData = data;
	});

	$scope.searchdata = function () {
		return $scope.autoData;
	}

	$scope.somethingTyped = function (typedthings) {
		$scope.searchText = typedthings;

		$scope.newautoData = predictiveSearchData.searchdata(typedthings, $scope.searchIndexes);

		$scope.newautoData.then(function (data) {
			$scope.autoData = data;
		});
	}

	$scope.somethingSelected = function (suggestionId) {
		if (suggestionId && suggestionId > 0) {
			var obj = predictiveSearchData.getItemById(suggestionId, $scope.autoData);
			predictiveSearchData.redirect(obj.url);
		}
		else {
			predictiveSearchData.redirect($scope.defaultUrl + "?searchText=" + $scope.searchText);
		}
	}
};