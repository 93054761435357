module.exports = function($sce) {
	return function (input, searchParam) {
		if (typeof input.name === 'function') return '';
		if (searchParam) {
		    var newMarkup = "";

		    var words = '(' +
				searchParam.split(/\ /).join(' |') + '|' +
				searchParam.split(/\ /).join('|') + ')',
				exp = new RegExp(words, 'gi');

		    if (words.length) {
		        newMarkup = input.name.replace(exp, "<span class=\"highlight\">$1</span>");

		        if (input.searchIndex == "single") {
		            if (input.description){
		                newMarkup += "<br/>";
		                newMarkup += input.description.replace(exp, "<span class=\"description highlight\">$1</span>");
		            }

		            if (input.url){
		                newMarkup += "<br/>";
		                newMarkup += "<span class=\"link\">"+input.url+"</span>";
		            }
		        }
		    }
		}
		return $sce.trustAsHtml(newMarkup);
	};
};